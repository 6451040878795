import React, { useMemo, useRef, useState } from 'react';
import { type Invoice, type InvoiceMetadataFeature, type InvoiceStatus, type PaymentMethod } from '@/types/api/invoice';
import { type CrudApi, CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { DateCell } from '@/components/ui/cells';
import { useTranslation } from '@/composables/translation';
import { getInvoiceList } from '@/composables/api';
import { DateTime } from 'luxon';
import { PayInvoiceDialog } from '@/pages/settings/billing/PayInvoiceDialog';
import { DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { DownloadIcon, Pencil2Icon } from '@radix-ui/react-icons';
import { usePermissions } from '@/composables/permissions';
import { INVOICE_STATUS_TYPES, PAYMENT_METHODS, TRANSACTION_STATUS_TYPES } from '@/components/utils/translations';
import { downloadFileURL } from '@/composables/utils';
import type { PaginationState } from '@tanstack/react-table';

export function Invoices() {
    const { t, to } = useTranslation('settings.invoices');
    const { isMaster } = usePermissions();
    const crudApi = useRef<CrudApi>();
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    });
    const schema = useMemo<CrudSchema<Invoice>>(
        () => [
            {
                id: 'invoice_number',
                type: CrudInputType.TEXT,
                columnDef: {
                    id: 'invoice_number',
                    accessorKey: 'invoice_number',
                    header: t('table.number')
                }
            },
            {
                id: 'invoice_to.name',
                type: CrudInputType.TEXT,
                columnDef: {
                    id: 'invoice_to.name',
                    accessorKey: 'invoice_to.name',
                    header: t('table.name')
                }
            },
            {
                id: 'status',
                type: CrudInputType.SELECT,
                options: INVOICE_STATUS_TYPES,
                columnDef: {
                    id: 'status',
                    accessorKey: 'status',
                    header: t('table.status'),
                    cell: ({ cell }) => {
                        const value = cell.getValue<InvoiceStatus>();
                        return to(INVOICE_STATUS_TYPES.find(t => t.value === value)?.label);
                    }
                }
            },
            {
                id: 'last_transaction.status',
                type: CrudInputType.SELECT,
                options: TRANSACTION_STATUS_TYPES,
                columnDef: {
                    id: 'last_transaction.status',
                    accessorKey: 'last_transaction.status',
                    header: t('table.transaction-status'),
                    cell: ({ cell }) => {
                        const value = cell.getValue<InvoiceStatus>();
                        return to(TRANSACTION_STATUS_TYPES.find(t => t.value === value)?.label);
                    }
                }
            },
            {
                id: 'last_transaction.type',
                type: CrudInputType.TEXT,
                columnDef: {
                    id: 'last_transaction.type',
                    accessorKey: 'last_transaction.type',
                    header: t('table.payment-type'),
                    cell: ({ cell }) => {
                        const value = cell.getValue<PaymentMethod>();
                        return to(PAYMENT_METHODS.find(p => p.value === value)?.label);
                    }
                }
            },
            {
                id: 'total_amount',
                type: CrudInputType.NUMBER,
                columnDef: {
                    id: 'total_amount',
                    accessorKey: 'total_amount',
                    header: t('table.amount')
                }
            },
            {
                id: 'usage_users',
                type: CrudInputType.NUMBER,
                columnDef: {
                    id: 'usage_users',
                    accessorKey: 'metadata',
                    header: t('table.usage-users'),
                    cell: ({ cell }) => {
                        const metadata = cell.getValue<InvoiceMetadataFeature[]>();
                        const usage = metadata.find(m => m.feature === 'neodesk');
                        if (!usage) {
                            return null;
                        }
                        return usage.rules_usage[0]?.usage;
                    }
                }
            },
            {
                id: 'usage_forms',
                type: CrudInputType.NUMBER,
                columnDef: {
                    id: 'usage_forms',
                    accessorKey: 'metadata',
                    header: t('table.usage-forms'),
                    cell: ({ cell }) => {
                        const metadata = cell.getValue<InvoiceMetadataFeature[]>();
                        const usage = metadata.find(m => m.feature === 'neoform');
                        if (!usage) {
                            return null;
                        }
                        return usage.rules_usage[0]?.usage;
                    }
                }
            },
            {
                id: 'invoice_date',
                type: CrudInputType.DATE,
                columnDef: {
                    id: 'invoice_date',
                    accessorKey: 'invoice_date',
                    header: t('table.date'),
                    cell: DateCell
                }
            },
            {
                id: 'invoice_range',
                type: CrudInputType.DATE,
                columnDef: {
                    id: 'invoice_range',
                    accessorKey: 'invoice_range',
                    header: t('table.range'),
                    cell: ({ cell }) => {
                        const { start_date, end_date } = cell.getValue<Invoice['invoice_range']>() ?? {};
                        const start = DateTime.fromISO(start_date);
                        const end = DateTime.fromISO(end_date);
                        if (!start.isValid || !end.isValid) {
                            return null;
                        }
                        return `${start.toFormat('DDD')} — ${end.toFormat('DDD')}`;
                    },
                    getCsvValue: (value) => {
                        if (!value?.start_date || !value?.end_date) {
                            return null;
                        }
                        return `${value.start_date} - ${value.end_date}`;
                    }
                }
            }
        ],
        []
    );
    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<Invoice, '_id'>
                    idKey="_id"
                    apiRef={crudApi}
                    schema={schema}
                    manualPagination
                    state={{ pagination }}
                    rowCount={rowCount}
                    actions={({ row }) => (
                        <>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        variant="ghost" size="icon"
                                        onClick={() => downloadFileURL(
                                            'invoice.pdf',
                                            row.original.file_url
                                        )}
                                    >
                                        <DownloadIcon />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('table.actions.download')}
                                </TooltipContent>
                            </Tooltip>
                            <PayInvoiceDialog
                                invoice_number={row.original.invoice_number}
                                transaction_amount={row.original.remain}
                                onSuccess={() => crudApi.current?.refreshList()}
                            >
                                {isMaster &&
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <DialogTrigger asChild>
                                                <Button variant="ghost" size="icon">
                                                    <Pencil2Icon />
                                                </Button>
                                            </DialogTrigger>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {t('table.actions.pay')}
                                        </TooltipContent>
                                    </Tooltip>
                                }
                            </PayInvoiceDialog>
                        </>
                    )}
                    onRead={() => getInvoiceList({
                        offset: pagination.pageIndex * pagination.pageSize,
                        limit: pagination.pageSize
                    }).then(res => {
                        setPagination({
                            pageSize: res.data.limit,
                            pageIndex: pagination.pageIndex
                        });
                        setRowCount(res.data.nb_rows);
                        return res.data.data;
                    })}
                    onPaginationChange={setPagination}
                />
            </CardContent>
        </>
    );
}
