import { type Language } from '@/composables/translation';
import { type DateTime } from 'luxon';
import { type Feature } from '@/composables/features';

export interface FormValues {
    title: string;
    name: string;
    description: string;
    features: Feature[];
    notify: '';
    lang: Language;
    user_infos: UserInfos | null;
    contract: OrgContract;
}

export interface OrgContract {
    start_date: string | DateTime;
    duration_months: number;
    billing: {
        start_date: string | DateTime;
        features_invoice: InvoiceFeature[];
    };
}

export interface InvoiceFeature {
    name: string;
    description?: string;
    start_date?: string | DateTime;
    rules: InvoiceRule[];
}

export interface InvoiceRule {
    name: string;
    definition: any;
    stages: Stage[];
}

export interface Stage {
    id: string;
    start: number;
    unit_price: number;
}

export interface ConfigureOrgFormValues {
    client_id: string;
    client_secret: string;
    grant_type: string;
    password: string;
    scope: string;
    tenant_id: string;
    username: string;
}

export interface UserInfos {
    firstname?: string;
    lastname?: string;
    email?: string;
    external_id?: string | null;
    role?: string;
}

interface OrgEnv {
    features: string[];
}

export interface Org {
    title: string;
    name: string;
    description: string;
    folder: string;
    env: OrgEnv;
    lang: Language;
    contract: OrgContract;
}

export interface PostOrgDto {
    notify?: string | null;
    user_infos: UserInfos | null;
    is_externe: boolean;
    org: Org;
}

export const roleOptions = [
    'lawyer',
    'sales',
    'elawyer',
    'agent',
    'legalintern',
    'notary',
    'group'
] as const;

export type Roles = typeof roleOptions[number];
